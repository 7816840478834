import { STATES } from "../constants";

const getPartialValueString = ({ key, string }) => {
  const regex = new RegExp(`/${key}=([^:/]+)`);
  const match = string.match(regex);

  return match ? match[1] : null;
};

export const getMetricAndStateFromDownloadURL = (url) => {
  const metric = getPartialValueString({
    key: "metric",
    string: url,
  });

  const zipCode = getPartialValueString({
    key: "state",
    string: url,
  });

  const selectedState = STATES.find(
    (state) => state?.zipcode === zipCode
  )?.name;

  return {
    metric,
    selectedState,
  };
};
