export const COLUMNS_MAPPER = {
  pm25__median_concentration: "pm25:concentration",
  // population__count: "population:count",
  // population__percent: "population:count",
  total_population__count: "total_population:count",
  total_population__percent: "total_population:count",
  foreign_born_population__percent: "foreign_born_population:percent",
  cdc_obesity__percent: "cdc_obesity:percent",
  age_10_to_14__percent: "age_10_to_14:percent",
  age_15_to_17__percent: "age_15_to_17:percent",
  age_18_to_29__percent: "age_18_to_29:percent",
  age_30_to_44__percent: "age_30_to_44:percent",
  age_45_to_64__percent: "age_45_to_64:percent",
  age_50_to_64__percent: "age_50_to_64:percent",
  age_5_to_9__percent: "age_5_to_9:percent",
  age_lt_18__percent: "age_lt_18:percent",
  age_gt_1__percent: "age_gt_1:percent",
  age_gte_65__percent: "age_gte_65:percent",
  age_gte_80__percent: "age_gte_80:percent",
  age_lt_5__percent: "age_lt_5:percent",
  age_median__count: "age_median:count",
  race_asian__percent: "race_asian:percent",
  race_black__percent: "race_black:percent",
  race_white__percent: "race_white:percent",
  sex_female__percent: "sex_female:percent",
  race_more_than_one__percent: "race_more_than_one:percent",
  race_native_pacific_islander__percent: "race_native_pacific_islander:percent",
  race_american_indian_alaska_native__percent:
    "race_american_indian_alaska_native:percent",
  sex_male__percent: "sex_male:percent",
  population_density__count: "population_density:count",
  ethnicity_hispanic__percent: "ethnicity_hispanic:percent",
  ethnicity_non_hispanic__percent: "ethnicity_non_hispanic:percent",
  hi_private_any__percent: "hi_private_any:percent",
  cdc_medicine_bp__percent: "cdc_medicine_bp:percent",
  lbw_very_low__count: "lbw_very_low:count",
  lbw_very_low__percent: "lbw_very_low:count",
  hi_private_employer_direct__percent: "hi_private_employer_direct:percent",
  obesity_total__count: "obesity_total:count",
  obesity_total__percent: "obesity_total:count",
  hi_private_medicare__percent: "hi_private_medicare:percent",
  obesity_class_3__count: "obesity_class_3:count",
  obesity_class_3__percent: "obesity_class_3:count",
  ckd_kdigo_lt_30__count: "ckd_kdigo_lt_30:count",
  ckd_kdigo_lt_30__percent: "ckd_kdigo_lt_30:count",
  cdc_mental__percent: "cdc_mental:percent",
  ckd_kdigo_lt_60__count: "ckd_kdigo_lt_60:count",
  ckd_kdigo_lt_60__percent: "ckd_kdigo_lt_60:count",
  lbw_late_preterm__count: "lbw_late_preterm:count",
  lbw_late_preterm__percent: "lbw_late_preterm:count",
  cdc_smoking__percent: "cdc_smoking:percent",
  cdc_heart__percent: "cdc_heart:percent",
  hi_medicaid_any__percent: "hi_medicaid_any:percent",
  hi_public_other__percent: "hi_public_other:percent",
  cdc_cholesterol_high__percent: "cdc_cholesterol_high:percent",
  hi_tricare_va__percent: "hi_tricare_va:percent",
  lbw_low__count: "lbw_low:count",
  lbw_low__percent: "lbw_low:count",
  hi_medicaid_any_below_64__percent: "hi_medicaid_any_below_64:percent",
  stroke_diag__count: "stroke_diag:count",
  stroke_diag__percent: "stroke_diag:count",
  lbw_extrem_preterm__count: "lbw_extrem_preterm:count",
  lbw_extrem_preterm__percent: "lbw_extrem_preterm:count",
  cdc_health__percent: "cdc_health:percent",
  hi_private_employer__percent: "hi_private_employer:percent",
  cdc_cervical__percent: "cdc_cervical:percent",
  cdc_sleep__percent: "cdc_sleep:percent",
  diabetes_diag__count: "diabetes_diag:count",
  diabetes_diag__percent: "diabetes_diag:count",
  lbw_preterm__count: "lbw_preterm:count",
  lbw_preterm__percent: "lbw_preterm:count",
  lbw_very_preterm__count: "lbw_very_preterm:count",
  lbw_very_preterm__percent: "lbw_very_preterm:count",
  cdc_arthritis__percent: "cdc_arthritis:percent",
  cdc_doctor__percent: "cdc_doctor:percent",
  cdc_mammography__percent: "cdc_mammography:percent",
  cdc_leisure__percent: "cdc_leisure:percent",
  cdc_kidney__percent: "cdc_kidney:percent",
  obesity_class_2__count: "obesity_class_2:count",
  obesity_class_2__percent: "obesity_class_2:count",
  cdc_preventive_m__percent: "cdc_preventive_m:percent",
  cdc_dentist__percent: "cdc_dentist:percent",
  cdc_physical__percent: "cdc_physical:percent",
  htn_diag__count: "htn_diag:count",
  htn_diag__percent: "htn_diag:count",
  cdc_bingedrink__percent: "cdc_bingedrink:percent",
  ckd_cdc_lt_30__count: "ckd_cdc_lt_30:count",
  ckd_cdc_lt_30__percent: "ckd_cdc_lt_30:count",
  mi_diag__count: "mi_diag:count",
  mi_diag__percent: "mi_diag:count",
  cdc_depression__percent: "cdc_depression:percent",
  cdc_pulmonary__percent: "cdc_pulmonary:percent",
  cdc_bphigh__percent: "cdc_bphigh:percent",
  cdc_asthma__percent: "cdc_asthma:percent",
  hi_medicare_only__percent: "hi_medicare_only:percent",
  cdc_cholesterol__percent: "cdc_cholesterol:percent",
  htn_bp__count: "htn_bp:count",
  htn_bp__percent: "htn_bp:count",
  ckd_cdc_lt_60__count: "ckd_cdc_lt_60:count",
  ckd_cdc_lt_60__percent: "ckd_cdc_lt_60:count",
  hi_medicare_public_only__percent: "hi_medicare_public_only:percent",
  cdc_fecal__percent: "cdc_fecal:percent",
  cdc_cancer__percent: "cdc_cancer:percent",
  obesity_class_1__count: "obesity_class_1:count",
  obesity_class_1__percent: "obesity_class_1:count",
  hi_private_self_employed__percent: "hi_private_self_employed:percent",
  cdc_strokes__percent: "cdc_strokes:percent",
  cdc_diabetes__percent: "cdc_diabetes:percent",
  cdc_teeth_lost__percent: "cdc_teeth_lost:percent",
  hi_uninsured__percent: "hi_uninsured:percent",
  obesity_ow__count: "obesity_ow:count",
  obesity_ow__percent: "obesity_ow:count",
  cdc_preventive_f__percent: "cdc_preventive_f:percent",
  cdc_insurance__percent: "cdc_insurance:percent",
  bb_any_connection__count: "bb_any_connection:count",
  bb_any_connection__per_capita: "bb_any_connection:count",
  bb_with_broadband_any__percent: "bb_with_broadband_any:percent",
  tobacco_retail_outlets__count: "tobacco_retail_outlets:count",
  tobacco_retail_outlets__per_capita: "tobacco_retail_outlets:count",
  tree_coverage__percent: "tree_coverage:percent",
  bb_with_internet_no_subscription__percent:
    "bb_with_internet_no_subscription:percent",
  bb_with_smartphone__percent: "bb_with_smartphone:percent",
  impervious_area__percent: "impervious_area:percent",
  bb_with_broadband__percent: "bb_with_broadband:percent",
  bb_with_broadband_no_internet_subscription__percent:
    "bb_with_broadband_no_internet_subscription:percent",
  bb_with_pc_only__percent: "bb_with_pc_only:percent",
  bb_no_internet_access__percent: "bb_no_internet_access:percent",
  bb_high_speed__count: "bb_high_speed:count",
  bb_high_speed__per_capita: "bb_high_speed:count",
  bb_with_internet_subscription__percent:
    "bb_with_internet_subscription:percent",
  bb_no_computer_device__percent: "bb_no_computer_device:percent",
  bb_with_tablet_only__percent: "bb_with_tablet_only:percent",
  unemployed__percent: "unemployed:percent",
  household_food_stamps__percent: "household_food_stamps:percent",
  et_public_commute_15min__percent: "et_public_commute_15min:percent",
  et_work_no_car__percent: "et_work_no_car:percent",
  et_drive_to_work__percent: "et_drive_to_work:percent",
  below_poverty__percent: "below_poverty:percent",
  et_public_transit__percent: "et_public_transit:percent",
  per_capita_income__median_dollars: "per_capita_income:dollars",
  household_public_assistance__percent: "household_public_assistance:percent",
  non_institutionalized_population__count:
    "non_institutionalized_population:count",
  non_institutionalized_population__percent:
    "non_institutionalized_population:count",
  et_public_commute_29min__percent: "et_public_commute_29min:percent",
  et_commute_time_29min__percent: "et_commute_time_29min:percent",
  et_commute_time_15min__percent: "et_commute_time_15min:percent",
  et_commute_time_59min__percent: "et_commute_time_59min:percent",
  et_public_commute_59min__percent: "et_public_commute_59min:percent",
  et_household_no_vehicle__percent: "et_household_no_vehicle:percent",
  et_walk_to_work__percent: "et_walk_to_work:percent",
  et_commute_time_60min_up__percent: "et_commute_time_60min_up:percent",
  et_public_commute_60min_up__percent: "et_public_commute_60min_up:percent",
  household_income_median__median_dollars: "household_income_median:dollars",
  ahrq_ses__median: "ahrq_ses:index",
  gini_index__median: "gini_index:index",
  adi__median: "adi:index",
  graduate_degree__percent: "graduate_degree:percent",
  mercury_compounds__median_concentration: "mercury_compounds:concentration",
  xylenes__median_concentration: "xylenes:concentration",
  gas_1_3_butadiene__median_concentration: "gas_1_3_butadiene:concentration",
  methyl_chloride__median_concentration: "methyl_chloride:concentration",
  ethylbenzene__median_concentration: "ethylbenzene:concentration",
  lead_compounds__median_concentration: "lead_compounds:concentration",
  carbon_tetrachloride__median_concentration:
    "carbon_tetrachloride:concentration",
  manganese_compounds__median_concentration:
    "manganese_compounds:concentration",
  diesel_pm__median_concentration: "diesel_pm:concentration",
  benzene__median_concentration: "benzene:concentration",
  methanol__median_concentration: "methanol:concentration",
  formaldehyde__median_concentration: "formaldehyde:concentration",
  acetaldehyde__median_concentration: "acetaldehyde:concentration",
  toluene__median_concentration: "toluene:concentration",
  hexane__median_concentration: "hexane:concentration",
  nickel_compounds__median_concentration: "nickel_compounds:concentration",
  occupied_house_electricity__percent: "occupied_house_electricity:percent",
  occupied_house_coal__percent: "occupied_house_coal:percent",
  occupied_house_bottled_tank_lp_gas__percent:
    "occupied_house_bottled_tank_lp_gas:percent",
  in_county_move_1_year_ago__percent: "in_county_move_1_year_ago:percent",
  households__count: "households:count",
  households__per_capita: "households:count",
  civilian_labor_force__count: "civilian_labor_force:count",
  civilian_labor_force__per_capita: "civilian_labor_force:count",
  eviction_count__count: "eviction_count:count",
  eviction_count__per_capita: "eviction_count:count",
  owner_occupied_housing_units__percent: "owner_occupied_housing_units:percent",
  occupied_house_solar_energy__percent: "occupied_house_solar_energy:percent",
  occupied_house_wood__percent: "occupied_house_wood:percent",
  household_size_average__count: "household_size_average:count",
  household_size_average__per_capita: "household_size_average:count",
  units_built_1979__percent: "units_built_1979:percent",
  different_state__percent: "different_state:percent",
  in_state_move_1_year_ago__percent: "in_state_move_1_year_ago:percent",
  eviction_filing_rate__percent: "eviction_filing_rate:percent",
  vacant_housing_units__percent: "vacant_housing_units:percent",
  eviction_rate__percent: "eviction_rate:percent",
  units_10_or_more__percent: "units_10_or_more:percent",
  more_than_one_person_per_room__percent:
    "more_than_one_person_per_room:percent",
  occupied_house_utility_gas__percent: "occupied_house_utility_gas:percent",
  renter_occupied_housing_units__percent:
    "renter_occupied_housing_units:percent",
  housing_units__count: "housing_units:count",
  housing_units__per_capita: "housing_units:count",
  occupied_house_other__percent: "occupied_house_other:percent",
  occupied_house_no_fuel__percent: "occupied_house_no_fuel:percent",
  year_built_median__median_dollars: "year_built_median:dollars",
  occupied_house_oil__percent: "occupied_house_oil:percent",
  lacking_kitchen_facilities__percent: "lacking_kitchen_facilities:percent",
  lacking_plumbing_facilities__percent: "lacking_plumbing_facilities:percent",
  rent_median__median_dollars: "rent_median:dollars",
  home_value_median__median_dollars: "home_value_median:dollars",
  civilian_employed_population__count: "civilian_employed_population:count",
  civilian_employed_population__per_capita:
    "civilian_employed_population:count",
  owner_occupied_housing_units_with_children__percent:
    "owner_occupied_housing_units_with_children:percent",
  eviction_filings__count: "eviction_filings:count",
  eviction_filings__per_capita: "eviction_filings:count",
  parks__count: "parks:count",
  parks__per_capita: "parks:count",
  liquor_stores__count: "liquor_stores:count",
  liquor_stores__per_capita: "liquor_stores:count",
  religious_locations__count: "religious_locations:count",
  religious_locations__per_capita: "religious_locations:count",
  bar_nightclub__count: "bar_nightclub:count",
  bar_nightclub__per_capita: "bar_nightclub:count",
  out_of_state_ownership__percent: "out_of_state_ownership:percent",
  cost_burdened_morgage_holders__percent:
    "cost_burdened_morgage_holders:percent",
  cost_burdened_renters__percent: "cost_burdened_renters:percent",
  coi__median: "coi:index",
  hvi__median_concentration: "hvi:concentration",
  svi__median: "svi:index",
  lilatracts_halfand10__count: "lilatracts_halfand10:count",
  lilatracts_halfand10__per_capita: "lilatracts_halfand10:count",
  lilatracts_1and10__count: "lilatracts_1and10:count",
  lilatracts_1and10__per_capita: "lilatracts_1and10:count",
  lahalfand10__count: "lahalfand10:count",
  lahalfand10__per_capita: "lahalfand10:count",
  latractsvehicle_20__count: "latractsvehicle_20:count",
  latractsvehicle_20__per_capita: "latractsvehicle_20:count",
  la1and20__count: "la1and20:count",
  la1and20__per_capita: "la1and20:count",
  lilatracts_1and20__count: "lilatracts_1and20:count",
  lilatracts_1and20__per_capita: "lilatracts_1and20:count",
  la1and10__count: "la1and10:count",
  la1and10__per_capita: "la1and10:count",
  lilatracts_vehicle__count: "lilatracts_vehicle:count",
  lilatracts_vehicle__per_capita: "lilatracts_vehicle:count",
};
